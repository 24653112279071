<template>
    <div class="block-company">
        <StackRouterHeaderBar :title="$translate('BLOCK_FRIEND')" :show-title="true" />
        <div class="title" v-html="$translate('BLOCK_COMPANY')" />
        <div class="sub-title">해당되는 회원과는 서로 소개되지 않습니다!</div>
        <div class="section">
            <div class="section-title" v-html="$translate('BLOCKED_COMPANY')" />
            <div class="block-item add" @click="onClickBtn">
                <i class="material-icons m-r-4">add</i>
                <span class="c-white">차단목록 추가</span>
            </div>
            <div class="block-item" v-for="item in blockedCompany" :key="item.id">
                <span class="f-15" v-html="item.company_name" />
                <i
                    @click="onClickCancelBtn(item.id)"
                    class="material-icons f-15 c-white"
                    style="padding: 2px; border-radius: 50%; background: #b9bdc1"
                    >close</i
                >
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'BlockCompaniesPage',
    computed: {
        blockedCompany() {
            return this.$store.getters.blockedCompany
        },
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            try {
                const res = await userService.getBlockProfile(this.$store.getters.me.id, 'company')
                this.$store.commit('setBlockedCompany', res.data)
            } catch (e) {
                console.log(e)
            }
        },
        async onClickBtn() {
            await this.$modal.custom({
                component: 'ModalCollegeSearch',
                options: {
                    blockType: 'company',
                },
            })
        },
        async onClickCancelBtn(id) {
            try {
                await userService.cancelBlockProfile(this.$store.getters.me.id, id)
                this.$toast.success('삭제되었습니다')
                await this.init()
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>
